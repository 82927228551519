.edit-header {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 10vh;
    top: 0;
    z-index: 99;
}

/* Editerのページ切り替え用ボタンとリンク */
.edit-header-Link {
    flex: 1;
}
.edit-header-Link > button {
    width: 100%;
    height: 10vh;

    color: #fff;
    background-color: #242222;
    border: 1px solid #fff;
    
    -webkit-appearance: none;
}
.edit-header > button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    background-color: #242222;
    border: 1px solid #fff;

    -webkit-appearance: none;
}

.edit-header-Link > button:hover, .edit-header > button:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}

/* EditForNewsとEditForCalの入力フォーム */
.edit-news-section, .edit-calendar-section {
    margin-top: 200px;
    /* background: blue; */
}

.edit-inputs, .edit-textarea-wrap {
    /* margin: 0 10rem; */
    padding: 20px 10rem;
    display: flex;
    border-top: 1px solid #e3e9e9;
}
.edit-label, .edit-textarea-label {
    width: 30%;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
}
.edit-label > label, .edit-textarea-label > label {
    /* font-size: 1.2rem; */
}
.edit-input, .edit-textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
}
.edit-input > input, .edit-textarea > textarea {
    width: 70%;
}
.edit-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit-btn > button {
    width: 5rem;
    height: 3rem;
    font-size: 1.1rem;
    border-radius: 2px;
    color: #fff;
    background-color: #242222;
    border: 1px solid #242222;
}
.edit-btn > button:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}

/* カレンダーリスト */
.calendar-list-wrap {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}
.calendar-list {
    list-style: none;
    display: flex;
    border-bottom: 1px solid #e3e9e9;
    position: relative;
    width: 60vw;
    margin: 0.5rem 0;
}
.calendar-list-date {
    width: 20%;
}
/* .calendar-list-title {

} */
.calendar-list-del-btn {
    position: absolute;
    right: 0;
    /* top: -1rem; */
    padding: 0 1rem;
    background: red;
    color: #fff;
    border: 1px solid black;
    border-radius: 2px;
}
.calendar-list-del-btn:hover {
    background: #fff;
    color:rgb(26, 23, 23);
}
.cal-margin {
    height: 10vh;
}
@media screen and (max-width: 960px) {

}
@media screen and (max-width: 415px) {
    /* .edit-header {
        top: 90vh;
    } */
    .edit-inputs, .edit-textarea-wrap {
        padding: 20px 10px;
        flex-direction: column;
    }
    .edit-input > input, .edit-textarea > textarea {
        width: 100%;
    }

    .calendar-list {
        width: 95vw;
    }
    .calendar-list-date {
        width: 30%;
    }
}