.recruit-link-section {
    padding: 2rem 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);

    background-color: var(--base);
}

.recruit-new-btn, .recruit-middle-btn {
    margin: 2rem 0;
    width: 80vw;
    height: 3rem;
    border-radius: 1rem;
    box-shadow: .3rem .3rem .6rem #c8d0e7, 
                -.2rem -.2rem .5rem #FFF;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease;

    grid-column: 1 / 2;
    grid-row: 5 / 6;
    color: #9baacf;
}
.recruit-new-btn span, .recruit-middle-btn span {
    position: relative;
	z-index: 3;
    font-weight: 700;
	color:var(--news-btn-color);
}
.recruit-new-btn:hover span, .recruit-middle-btn:hover span {
    /* color: #6d5dfc; */
    color: var(--nav-links-hover);
}
.recruit-new-btn:hover i, .recruit-middle-btn:hover i {
    transform: translate(145%, 10%);
} 
.recruit-new-btn:active, .recruit-middle-btn:active {
    box-shadow: inset .2rem .2rem .5rem #c8d0e7, 
    inset -.2rem -.2rem .5rem #FFFF;
}

@media screen and (max-width: 960px) {
    .recruit-link-section {
        padding: 8vw 12vw;   
    }
}
@media screen and (max-width: 768px) {
    .recruit-link-section {
        padding: 8vw 5vw;
    }
}