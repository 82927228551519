.top-page {
    height: 100vh;
    display: flex;
    background-color: var(--base);
}
.top-background {
    background: url(./images/chair.JPG);
    background: url(./images/img-2.jpg);
    background-repeat:  no-repeat;                  
    background-position: center center; 
    background-size: cover; 
    /* background-attachment: fixed; */
    width: 65vw;
    height: 100vh;  
    /* z-index: -99; */
}
.top-contents {
    width: 29vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    
}
.top-contents > h1, .top-contents-p {
    margin-left: -50px;
}
.top-contents > h1 {
    font-size: 3.5rem;
    letter-spacing: 0.01rem;
    /* 背景色にグラデーションを設定 */
    background: var(--home-title);
    /* background: -webkit-linear-gradient(90deg,#0065b0 0,#dd0b31 100%); */
    /* テキストでくり抜く */
    -webkit-background-clip: text;
    /* くり抜いた部分が背景を表示 */
    -webkit-text-fill-color: transparent;
}


.top-contents-p > p {
    font-weight: 100;
    color: var(--base-txt);
}
.top-page-link {
    margin-top: 4vh;
    margin-left: -50px;
}
/* .top-page-btn {
    position: relative;
	overflow: hidden;
    text-decoration: none;
	display: inline-block;
    text-align: center;
   	border: 1px solid var(--top-btn-border);
    background: var(--top-btn-back);
    border-radius: 3px;
    padding: 20px 60px;
    font-size: 13px;
    
    outline: none;  
    transition: ease .2s;
    cursor: pointer;
}
.top-page-btn span {
    position: relative;
	z-index: 3;
    font-weight: 700;
	color:var(--top-btn-back-hover);
}
.top-page-btn:hover span {
    color:var(--top-btn-color-hover);
}

.top-page-btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background:#fff;
    width: 100%;
   height: 100%;
    transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
   transform-origin: right top;
}
.top-page-btn:hover:before{
	transform-origin:left top;
	transform:scale(1, 1);
}
.top-page-btn i {
    font-size: 1rem;
    transform: translate(10%, 7%);
    transition: all .3s;
}
.top-page-btn:hover i {
    transform: translate(45%, 7%);
} */
.top-page-btn {
    width: 15rem;
    height: 4rem;
    border-radius: 1rem;
    box-shadow: .3rem .3rem .6rem #c8d0e7, 
                -.2rem -.2rem .5rem #FFF;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease;

    grid-column: 1 / 2;
    grid-row: 4 / 5;
    background: #6d5dfc;
    background: #67BCE2;
    box-shadow:inset .2rem .2rem 1rem #8abdff, 
              inset -.2rem -.2rem 1rem #009AE0,
              .3rem .3rem .6rem #c8d0e7, 
              -.2rem -.2rem .5rem #FFFF;
    color: #E4EBF5;
}
.top-page-btn span {
    position: relative;
	z-index: 3;
    font-weight: 700;
	color:var(--base-txt);
}
.top-page-btn i {
    font-size: 1rem;
    transform: translate(100%, 10%);
    transition: all .3s;
}
.top-page-btn:hover span {
    color: var(--white);
}
.top-page-btn:hover i {
    transform: translate(145%, 10%);
} 
.top-page-btn:active {
    box-shadow:inset .2rem .2rem 1rem #009AE0, 
               inset -.2rem -.2rem 1rem #8abdff;
}


.top-side-scroll {
    width: 6vw;
    /* background: red; */
    border-left: 1px solid rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;   
}
.top-side-scroll > span {
    writing-mode: vertical-rl;
}
.top-side-scroll::after {
    content: "";
    /*描画位置*/
    /* position: absolute;
    top: 0; */
    /*線の形状*/
    width: 1px;
    height: 30px;
    background: black;
    /*線の動き1.4秒かけて動く。永遠にループ*/
    animation: pathmove 2.5s ease-in-out infinite;
    opacity:0;
}
/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove{
    0%{
      height:0;
      top:0;
      opacity: 0;
    }
    30%{
      height:30px;
      opacity: 1;
    }
    100%{
      height:0;
      top:50px;
      opacity: 0;
    }
}
@media screen and (max-width: 960px) {
    .top-page {
        /* margin-top: 103px; */
        flex-direction: column;
        align-items: center;
    }
    .top-background {
        /* margin-right: -35vw; */
        width: 80vw;
        height: 60vh;
        margin-right: -20vw;
        background-size: cover;
        /* width: 100vw; */
        /* background-position: center center; */
    }
    .top-contents {
        /* width: 100vw; */
        min-width: 80vw;
        /* margin: 0 5rem; */
    }
    .top-contents > h1 {
        margin-left: 0;
        margin-top: -10vh;
        /* font-size: 5rem; */
    }
    .top-contents-p,
    .top-page-link {
        margin-left: 0;
        /* display: flex; */
        /* flex-direction: column; */
    }
    .top-side-scroll {
        display: none;
    }
}
@media screen and (max-width: 415px) {
    .top-contents > h1 {
        font-size: 3rem;
        margin-top: -3vh;
    }
    /* .top-contents > p {
        font-size: 1px;
    } */
        
}

