.news-section {
    padding: 0 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);
    background: var(--base);
}
.news-lists {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* justify-items: center;
    align-items: center; */
    padding: 15vh 0;
}
.news-list {
    /* width: 40vw;
    height: 20vw; */
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1px solid rgba(255,255,255,0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
      -9px -9px 16px rgba(255, 255, 255, 0.5),
       inset 3px 3px 7px rgba(136, 165, 191, 0.48), 
      inset -3px -3px 7px #FFFFFF;
    background: linear-gradient(318.32deg, rgba(163, 177, 198, 0.1) 0%, rgba(163, 177, 198, 0.1) 55%, rgba(163, 177, 198, 0.25) 100%);
    text-decoration: none;
    color: #31344b;
    padding: 2rem;
    margin: 10px;
}
/* .news-list-content {
    align-self: center;
} */
.news-list-top {
    width: 100%;
    padding: 0 1rem;
    border-bottom: 1px solid rgb(235, 235, 235);
}
.news-list-date {
    align-self: flex-start;
}
.news-list-title {
    font-weight: 700;
}
.news-list-content {
    margin-top: 10%;
    white-space: pre-wrap;
}
.news-list-group {
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 0.3rem 0;
}
.news-list-btn-container {
    position: absolute;
    right: 0;
    top: -1.8rem;
}
.news-list-btn-delete {
    padding: 0.4rem 1rem;
    background: red;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2px;
}
.news-list-btn-delete:hover {
    background: #fff;
    color:rgb(26, 23, 23);
}
.news-list-btn-edit {
    padding: 0.4rem 1rem;
    background: green;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2px;
}
.news-list-btn-edit:hover {
    background: #fff;
    color:rgb(26, 23, 23);
}
.newsForOne-section {
    padding: 10vh 0;
    background: var(--base);
}
/* edit */
.news-edit-text-container {
    width: 100%;
    height: 90%;
}
.news-list-edit-btns {
    display: flex;
    justify-content: space-around;
}
.news-list-edit-btns > button {
    padding: 0.4rem 1rem;
    border: 1px solid #ffffff;
    border-radius: 2px;
    margin: 0.4rem 1rem;
}
.news-list-edit-btns > button:hover {
    background: #fff;
    color:rgb(26, 23, 23);
}
.news-list-edit-btns > button:first-of-type {
    background: var(--base-txt);
    color: var(--base);
}
.news-list-edit-btns > button:nth-of-type(2) {
    background: var(--base);
    color: var(--base-txt);
}
@media screen and (max-width: 768px) {
    /* .news-list {
        width: 40vw;
        height: 30vw;
    } */
    .news-lists {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 415px) {
    /* .news-lists {
        grid-template-columns: 1fr;
    } */
    /* .news-list {
        width: 80vw;
        height: 30vh;
    } */
}