.covid-section {
    margin: 0 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 
}
.covid-container {
    margin-top: 4vh;
    border: 5px solid var(--covid-border);
    border-radius: 4px;
    width: 70vw;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
}
/* .covid-container.active {
    transition: all 0.5s ease;
    height: 200px;
} */
.covid-container > h2 {
    color: var(--covid-title);
}
.covid-btn {
    padding: 20px 60px;
    border-radius: 3px;
    border: 1px solid var(--covid-btn-border);
    background-color: var(--covid-btn-back);

    position: relative;
    overflow: hidden;
    display: inline-block;
    outline: none;  
    transition: ease .2s;
    cursor: pointer;
}
.covid-btn span {
    position: relative;
    z-index: 3;
    color:var(--covid-btn-color);
    font-weight: 700;
}
.covid-btn:hover span {
    color: var(--covid-btn-color-hover);
}
.covid-btn:before {
    content: '';
   /*絶対配置で位置を指定*/
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
   /*色や形状*/
    background:var(--covid-btn-back-hover);/*背景色*/
    width: 100%;
    width: 100%;
	height: 0;
    /*アニメーション*/
 	transition:.3s cubic-bezier(0.8, 0, 0.2, 1) 0s;
}
.covid-btn:hover:before {
    height: 100%;	
}
.covid-btn i {
    font-size: 0.8rem;
    transform: translate(30%, 5%);
    transition: all .3s;
}
.covid-btn:hover i {
    transform: translate(15%, 20%);
}
.covid-contents {
    display: none;
    padding: 2rem;
    flex-direction: column;
}
.covid-contents-listbox {
    padding-left: 4rem;
    margin: 1em 0;
    list-style: square;
}
.covid-container.active > .covid-contents {
    display: flex;
    transition: all 1s ease;
}
@media screen and (max-width: 960px) {
    .covid-section {
        margin: 0 12vw;
        border: none;
    }
}
@media screen and (max-width: 768px) {
    .covid-section {
        margin: 0 5vw;
    }
    .covid-container {
        width: 95vw;
    }
    .covid-container > h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }
}