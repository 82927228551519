.office-hours-section {
    padding: 0 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);

    background-color: var(--base);
}
.office-hours-heading {
    /* display: flex;
    justify-content: flex-start; */
    /* 背景色にグラデーションを設定 */
    background: var(--home-title);
    /* background: -webkit-linear-gradient(90deg,#0065b0 0,#dd0b31 100%); */
    /* テキストでくり抜く */
    -webkit-background-clip: text;
    /* くり抜いた部分が背景を表示 */
    -webkit-text-fill-color: transparent;
}
.office-hours-heading > h1 {
    padding-top: 100px;
}
.office-hours-table {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.office-timetable {
    border: 1px solid var(--table-border);
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 6px;
    overflow: hidden;
    text-align: center;
    width: 60%;

    border-radius: 9px;  
    background-color: #e0e5ec;
    box-shadow: 7px 7px 12px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    transition: all 250ms ease-in-out;
    border: 1px solid rgba(255,255,255,0.2);
}
.office-timetable thead th,
.office-timetable tbody th,
.office-timetable tbody td {
    padding: 1vh 2vw;
    border-bottom: 1px solid var(--table-border);
}
.office-timetable thead th {
    background: var(--table-back);
    color: var(--table-color);
}
.office-timetable tbody th {
    background: var(--table-back);
    color: var(--table-color);
}
.office-timetable thead th + th,
.office-timetable tbody td {
    border-left: 1px solid var(--table-border);
}
.office-timetable tbody tr:last-child th,
.office-timetable tbody tr:last-child td {
    border-bottom: none;
}

.dot {
    height: 2vw;
    width: 2vw;
    margin: 1vw;
    /* background: #EOE5EC; */
    box-shadow: 5px 5px 10px rgba(163, 177, 198, 0.6),
        -5px -5px 10px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
}
.triangle {
    /* √3 */
    border-bottom: 1.73vw solid #fff; 
    border-left: 1vw solid transparent; 
    border-right: 1vw solid transparent; 
    margin: 1vw;
    color: #fff;
    /* box-shadow: 5px 5px 10px rgba(163, 177, 198, 0.6),
        -5px -5px 10px rgba(255, 255, 255, 0.5); */
    
}
.calendar-section {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50%;
    height: 50%; */
    margin-top: 10vh;
    width: 100%;
}
.calendar-position {
    width: 60%;
    height: 60%;
}
/* スクロールバー */
.fc-scroller::-webkit-scrollbar-track {
    /* background: var(--cal-back); */
    background: var(--table-back);
    border-radius: 0; 
}
/* 日付配色 */
.fc-col-header-cell {
    /* background: var(--cal-back); */
    background: var(--table-back);
}
.fc-col-header {
    border-radius: 0;
}
.fc-day-sun {
    /* background-color: #ffeaea; */
    background: var(--table-back);
}

@media screen and (max-width: 960px) {
    .office-hours-section {
        padding: 0 12vw;
        border: none;
    }
    .calendar-position {
        width: 70%;
        height: 70%;
    }
}
@media screen and (max-width: 768px) {
    .office-hours-section {
        padding: 0 5vw;
    }
    table {
        width: 100%;
    }
    .calendar-position {
        width: 80%;
        height: 80%;
    }
}
@media screen and (max-width: 415px) {
    table {
        font-size: 14px;
    }
    .office-timetable {
        width: 100%;
    }
    .calendar-position {
        width: 100%;
        height: 120%;
    }
}