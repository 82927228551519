.services-home-section {
    padding: 0 6vw;
    /* padding: 100px 6vw; */
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);

    background: var(--base);
}
.services-home-heading {
    /* display: flex;
    justify-content: flex-start; */
    /* 背景色にグラデーションを設定 */
    background: var(--home-title);
    /* background: -webkit-linear-gradient(90deg,#0065b0 0,#dd0b31 100%); */
    /* テキストでくり抜く */
    -webkit-background-clip: text;
    /* くり抜いた部分が背景を表示 */
    -webkit-text-fill-color: transparent;
}
.services-home-heading > h1 {
    padding-top: 100px;
}
.services-home-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    /* gap: 2px; */
    margin-top: 10vh;
}
.services-link {
    border-radius: 9px;  
    /* background: linear-gradient(145deg, #cacaca, #f0f0f0);
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff; */

    background-color: #e0e5ec;
    /* dark */
    box-shadow: 7px 7px 12px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    transition: all 250ms ease-in-out;
    border: 1px solid rgba(255,255,255,0.2);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 15vw;
    height: 20vw;
    color: var(--services-home);
}
/* .services-link {
    width: 15vw;
    height: 20vw;
    background: var(--services-home-link);
    color: #fff;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.services-link:first-of-type {
    background: var(--services-home-link-first);
}
.services-link:nth-of-type(2) {
    background: var(--services-home-link-second);
}
.services-link:nth-of-type(3) {
    background: var(--services-home-link-third);
}
.services-link:nth-of-type(4) {
    background: var(--services-home-link-fourth);
} */
.services-link:hover {
    /* opacity: 1;
    transition: all 0.1s ease-in-out;
    transform: scale(1.09);
    z-index: 2;
    overflow: hidden; */

    box-shadow: 9px 9px 16px rgb(163 177 198 / 60%), -9px -9px 16px rgb(255 255 255 / 50%), inset 3px 3px 7px rgb(136 165 191 / 48%), inset -3px -3px 7px #ffffff;
}
.services-link-i {
    font-size: 5rem;
}
.services-link-text {
    color: var(--base-txt);
    margin-top: 20px; 
}

@media screen and (max-width: 960px) {
    .services-home-section {
        padding: 0 12vw;
        border: none;
    }
    .services-home-heading > h1 {
        padding-top: 5vh;
    }
    .services-home-links {
        grid-template-columns: 1fr 1fr;
    }
    .services-link {
        width: 25vw;
    }
    .services-link:nth-of-type(3), .services-link:nth-of-type(4){
        margin-top: 5vh;
    }
}
@media screen and (max-width: 768px) {
    .services-home-section {
        padding: 0 5vw;
    }
    .services-link {
        width: 35vw;
        height: 30vh;
    }
}
@media screen and (max-width: 415px) {
    .services-link {
        width: 40vw;
        height: 20vh;
    }
    .services-link-i {
        font-size: 4rem;
    }
}