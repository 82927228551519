.access-section {
    padding: 100px 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);

    background-color: var(--base);
}
.access-heading ,.phone-heading, .adress-heading {
    /* display: flex;
    justify-content: flex-start; */
    /* 背景色にグラデーションを設定 */
    background: var(--home-title);
    /* background: -webkit-linear-gradient(90deg,#0065b0 0,#dd0b31 100%); */
    /* テキストでくり抜く */
    -webkit-background-clip: text;
    /* くり抜いた部分が背景を表示 */
    -webkit-text-fill-color: transparent;
}
.acccess-heading > h1 {
    margin-top: 100px;
}
.access-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.phone-contents, .adress-contents {
    font-size: 1.7rem;
    margin: 1rem 0;
    font-weight: 700;
    color: #31344b
}
.GoogleMap {
    height: 50vh;
    width: 88vw;

    border-radius: 9px;  
    background-color: #e0e5ec;
    box-shadow: 7px 7px 12px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    transition: all 250ms ease-in-out;
    border: 1px solid rgba(255,255,255,0.2);
}

.access-googlemap-link {
    text-decoration: none;
}
.access-googlemap-btn {
    margin: 2rem 0;
    width: 80vw;
    height: 3rem;
    border-radius: 1rem;
    box-shadow: .3rem .3rem .6rem #c8d0e7, 
                -.2rem -.2rem .5rem #FFF;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease;

    grid-column: 1 / 2;
    grid-row: 5 / 6;
    color: #9baacf;
}
.access-googlemap-btn span {
    position: relative;
	z-index: 3;
    font-weight: 700;
	color:var(--news-btn-color);
}
.access-googlemap-btn i {

    font-size: 1rem;
    transform: translate(100%, 10%);
    transition: all .3s;
}
.access-googlemap-btn:hover span {
    /* color: #6d5dfc; */
    color: var(--nav-links-hover);
}
.access-googlemap-btn:hover i {
    transform: translate(145%, 10%);
} 
.access-googlemap-btn:active {
    box-shadow: inset .2rem .2rem .5rem #c8d0e7, 
    inset -.2rem -.2rem .5rem #FFFF;
}

.access-options {
    background-color: #e0e5ec;
    /* dark */
    box-shadow: 7px 7px 12px rgba(163, 177, 198, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
    transition: all 250ms ease-in-out;
    border: 1px solid rgba(255,255,255,0.2);

    display: flex;
    width: 100%;
    justify-content: space-around;
    border-radius: 9px;  
    margin: 5vw 0;
}
.access-option {
    list-style: none;
    margin: 1rem;
}
.access-option-pic {
    margin: auto;
    height: 10vw;
    width: 10vw;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.2);
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
      -9px -9px 16px rgba(255, 255, 255, 0.5),
       inset 3px 3px 7px rgba(136, 165, 191, 0.48), 
      inset -3px -3px 7px #FFFFFF;
    background: linear-gradient(318.32deg, rgba(163, 177, 198, 0.1) 0%, rgba(163, 177, 198, 0.1) 55%, rgba(163, 177, 198, 0.25) 100%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.access-option-pic > i {
    font-size: 2rem;
    color: #31344b;
}
.access-option-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.1rem;
}
@media screen and (max-width: 960px) {
    .access-section {
        padding: 100px 12vw;
        border: none;
    }
    .access-heading > h1 {
        margin-top: 5vh;
    }
    .GoogleMap {
        width: 76vw;
    }
}
@media screen and (max-width: 768px) {
    .access-section {
        padding: 100px 5vw;
    }
    .GoogleMap {
        width: 90vw;
    }
    .access-options {
        flex-direction: column;
        width: 100%;
    }
    .access-option {
        width: 100%;
        padding: 1rem 0;
    }
    /* .access-option:nth-of-type(2) {
        border: none;
        border-top: 1px solid #242222;
        border-bottom: 1px solid #242222;
    } */
    .access-option-pic {
        height: 20vw;
        width: 20vw;
    }
}
@media screen and (max-width: 415px) {
    /* .access-contents {
        flex-direction: column;
        align-items: center;
    }
    .phone-heading, .adress-heading {
        display: flex;
        justify-content: center;
    } */
    .phone-contents, .adress-contents {
        font-size: 1.4rem;
    }
}