.line-section {
    padding: 0 6vw;
    border-right: 1px solid rgb(235, 235, 235);
    border-left: 1px solid rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--base);
}
.line-container {
    margin-top: 4vh;
    border: 5px solid var(--line-border);
    border-radius: 4px;
    background-color: var(--base);
    box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
}
.line-outer {
    border-radius: 4px;
    width: 70vw;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 10px 10px 4px -10px #c3c3c3,
    inset -10px -10px 15px -10px #ffffff;
}

.line-outer > h2 {
    color: var(--line-title);
}

.line-container i {
    color: var(--line-icon);
}
.line-contents > ul {
    list-style: none;
    margin-top: 1rem;
}
.line-links {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-around;
}
.line-btn {
    display: none;
}
/* .line-btn {
    width: 15rem;
    height: 4rem;
    border-radius: 1rem;
    box-shadow: .3rem .3rem .6rem #c8d0e7, 
                -.2rem -.2rem .5rem #FFF;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s ease;

    grid-column: 1 / 2;
    grid-row: 5 / 6;
    color: #9baacf;
    text-decoration: none;
}
.line-btn span {
    position: relative;
	z-index: 3;
    font-weight: 700;
	color:var(--news-btn-color);
}
.line-btn i {

    font-size: 1rem;
    transform: translate(100%, 10%);
    transition: all .3s;
}
.line-btn:hover span {
    color: var(--line-icon);
}
.line-btn:hover i {
    transform: translate(145%, 10%);
} 
.line-btn:active {
    box-shadow: inset .2rem .2rem .5rem #c8d0e7, 
    inset -.2rem -.2rem .5rem #FFFF;
} */



.line-qr {
    width:100px;
}
@media screen and (max-width: 960px) {
    .line-section {
        padding: 0 12vw;
        border: none;
    }
    .line-btn {
        width: 15rem;
        height: 4rem;
        border-radius: 1rem;
        box-shadow: .3rem .3rem .6rem #c8d0e7, 
                    -.2rem -.2rem .5rem #FFF;
        justify-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s ease;
    
        grid-column: 1 / 2;
        grid-row: 5 / 6;
        color: #9baacf;
        text-decoration: none;
    }
    .line-btn span {
        position: relative;
        z-index: 3;
        font-weight: 700;
        color:var(--news-btn-color);
    }
    .line-btn i {
    
        font-size: 1rem;
        transform: translate(100%, 10%);
        transition: all .3s;
    }
    .line-btn:hover span {
        color: var(--line-icon);
    }
    .line-btn:hover i {
        transform: translate(145%, 10%);
    } 
    .line-btn:active {
        box-shadow: inset .2rem .2rem .5rem #c8d0e7, 
        inset -.2rem -.2rem .5rem #FFFF;
    }
}
@media screen and (max-width: 768px) {
    .line-section {
        padding: 0 5vw;
    }
    .line-container {
        width: 95vw;
    }
    .line-outer {
        width: 94vw;
    }
    .line-container p {
        font-size: 0.9rem;
        margin-top: 1rem;
    }
    .line-contents {
        margin-bottom: 1rem;
    }
    .line-contents li {
        font-size: 0.97rem;
    }
    .line-qr {
        display: none;
    }
}